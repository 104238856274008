import React from "react"
import { getMenuItems } from "./menu"
import { Link } from "gatsby"
import logo from "../images/logo.jpeg"

class Navbar extends React.Component {

	// Set default state
	state = {
		showMenu: false,
		showDropdown: null
	}

	// Invert state on click
	toggleMenu = () => {
		this.setState({
			showMenu: !this.state.showMenu
		})
	}

	// Set state to index
	toggleDropdown = (i) => {
		this.setState({
			showDropdown: i
		})
	}

	// Set class to 'is-active' if state index equals index parameter
	isDropdownActive = (i) => {
		return (
			this.state.showDropdown === i ? 'is-active' : ''
		)
	}

	// Returns dropdown type menu if true, else returns default menu
	Menu = (props) => {
		const menuIndex = props.index
		const menuLink = props.link
		const menuTitle = props.title
		const hasDropdown = props.hasDropdown;
		const dropdownDict = props.dropdownDict
		const dropdownActive = this.isDropdownActive(menuIndex);
		if(hasDropdown) {
			return (
			<div className={`navbar-item has-dropdown ${dropdownActive}`} onMouseOver={() => this.toggleDropdown(menuIndex)} onMouseOut={() => this.toggleDropdown(null)}>
			<Link className="navbar-link" to={menuLink}>{menuTitle}</Link>
			<div className="navbar-dropdown">
			{Object.keys(dropdownDict).map((dropdownItem, i) => {
				return (<Link className="navbar-item" to={dropdownDict[dropdownItem]}>{dropdownItem}</Link>)
			})}
			</div>
			</div>);
		}
		return (
			<Link className="navbar-item" to={menuLink}>{menuTitle}</Link>
		);
	}

	// Load menu items
	menuItems = getMenuItems()

	render() {

		{/* Set class to 'is-active' if true */}
		const menuActive = this.state.showMenu ? 'is-active' : '';
		const burgerActive = this.state.showMenu ? 'is-active' : '';

		
		{/* Menu items to navbar */}
		const menuList = Object.keys(this.menuItems).map((menuItem, i) => {
			const menuDict = this.menuItems[menuItem];
			const menuLink = menuDict["href"];
			const hasDropdown = menuDict["hasDropdown"];
			const dropdownDict = menuDict["dropdownDict"];
			return (
				<this.Menu index={i} link={menuLink} title={menuItem} hasDropdown={hasDropdown} dropdownDict={dropdownDict} /> 
			)
			
		});
	
		
		return (
	<header>
	<nav class="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
	<div class="navbar-brand">

	{/* Logo */}
	<a class="navbar-item" href="https://startrijles.nl">
	<img src={logo}/>
	</a>

	{/* Hamburger menu for mobile */}
	<a role="button" className={`navbar-burger ${burgerActive}`} onClick={this.toggleMenu} aria-label="menu" aria-expanded="false">
	<span aria-hidden="true"></span>
	<span aria-hidden="true"></span>
	<span aria-hidden="true"></span>
	</a>
	</div>

	{/* Menu items */}
	<div className={`navbar-menu ${menuActive}`}>
	<div class="navbar-start">
	{menuList}
	</div>
	</div>

	</nav>
	</header>)
	}
};
export default Navbar;


