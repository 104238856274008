import React from "react"

const menuItems = {
	"Home": {
		"href": "/",
		"hasDropdown": false,
		"dropdownDict": {}
	},
	"Theorie": {
		"href": "theorie",
		"hasDropdown": false,
		"dropdownDict": {}
	},
	"Tarieven": {
		"href": "tarieven",
		"hasDropdown": false,
		"dropdownDict": {}
	},
	"Examens": {
		"href":  "examens",
		"hasDropdown": true,
		"dropdownDict": {
			"2toDrive": "examens/2todrive",
			"Faalangst examen": "examens/faalangst",
			"Praktijkexamen": "examens/praktijkexamen",
			"Tussentijdse toets": "examens/tussentijdse-toets"
		}
	},
	"Inschrijvingen": {
		"href": "inschrijvingen",
		"hasDropdown": false,
		"dropdownDict": {}
	},
	"Proefles": {
		"href": "proefles",
		"hasDropdown": false,
		"dropdownDict": {}
	},
	"Contact": {
		"href": "contact",
		"hasDropdown": false,
		"dropdownDict": {}
	}
}

const getMenuItems = function() {
	return menuItems;
};

export { getMenuItems };
