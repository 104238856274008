import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Contact extends React.Component {
	render() {
		return (
		<section id="contact" class="section has-background-neutral-7">
		<div class="columns is centered">

		<div class="column">
		</div>

		<div class="column has-text-centered is-5">
		<h3 class="title">Neem contact op!</h3>

		<div class="field is-horizontal">
		<div class="field-body">

		<div class="field">
		<label class="label">Naam</label>
		<div class="control has-icons-left">
		<input class="input" type="text" placeholder="e.g. Jan de Vries" />
		<span class="icon is-small is-left">
		<FontAwesomeIcon icon="user" />
		</span>
		</div>
		</div>

		<div class="field">
		<label class="label">Email</label>
		<div class="control has-icons-left">
		<input class="input" type="text" placeholder="naam@voorbeeld.nl" />
		<span class="icon is-small is-left">
		<FontAwesomeIcon icon="envelope" />
		</span>
		</div>
		</div>

		</div>
		</div>

		<div class="field is-horizontal">
		<div class="field-body">

		<div class="field">
		<label class="label">Telefoonnummer</label>
		<div class="control has-icons-left">
		<input class="input" type="integer" placeholder="0612345678" />
		<span class="icon is-small is-left">
		<FontAwesomeIcon icon="phone" />
		</span>
		</div>
		</div>

		<div class="field">
		<label class="label">Hoe kunnen we je het best bereiken?</label>
		<div class="control is-expanded">
		<div class="select is-fullwidth">
		<select>
		<option>Via de telefoon</option>
		<option>Via de email</option>
		</select>
		</div>
		</div>
		</div>

		</div>
		</div>

		<div class="field">
		<label class="label">Waarmee kunnen we je helpen?</label>
		<div class="control">
		<textarea class="textarea"></textarea>
		</div>
		</div>

		<div class="field">
		<div class="control">
		<button class="button is-orange-3 is-medium">Verzenden</button>
		</div>
		</div>

		</div>

		<div class="column">
		</div>

		</div>
		</section>
		)
	}	
};

export default Contact;
