import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import footerStyles from "./footer.module.css"
import { Link } from "gatsby"
import { getMenuItems } from "./menu"

class Footer extends React.Component {

	// Load menu items
	menuItems = getMenuItems()

		
	render() {

	{/* Menu items to menu list */}
	const menuList = Object.keys(this.menuItems).map((menuItem, i) => (
	<li>
		<FontAwesomeIcon className={footerStyles.footerIcon} icon="angle-right" />	
		<Link to={this.menuItems[menuItem]["href"]}>{menuItem}</Link>
	</li>
		));
	
		return (
<footer class="footer has-background-dark has-text-white">
	<div class="container">
		<div class="columns is-centered">
			<div class="column">
				<h3 class="title is-4 has-text-white">Rijbewijs Academy</h3>
				<p>Regenboogstraat 123</p>
				<p>1234AB, Apeldoorn</p>
				<br />
				<p><FontAwesomeIcon className={footerStyles.footerIcon} icon="envelope" size="sm" />info@startrijles.nl</p>
				<p><FontAwesomeIcon className={footerStyles.footerIcon} icon="phone" size="sm" />06-12345678</p>
			</div>
			<div class="column">
				<h3 class="title is-6 has-text-white">Sitemap</h3>
				<ul>
					{menuList}			
				</ul>
			</div>
			<div class="column">
				<h3 class="title is-6 has-text-white">Overige</h3>
			</div>
			<div class="column">
				<h3 class="title is-6 has-text-white">Overige 2</h3>
			</div>

		</div>
	</div>
	<div className={footerStyles.footerCopyright}>
		<div class='columns'>
			<div class="column has-text-centered">
				© {new Date().getFullYear()}
				{` `}
				<a href="https://startrijles.nl">startrijles.nl</a> - Alle rechten voorbehouden
			</div>
		</div>
	</div>
</footer>
		)
	}
};

export default Footer; 
