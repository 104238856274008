/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Navbar from "../components/navbar"
import Contact from "../components/contact"
import Footer from "./footer"
import "./style.scss"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
	faCheck,
	faCheckCircle,
	faUser,
	faEnvelope,
	faPhone,
	faAngleRight,
	faEuroSign
} from "@fortawesome/free-solid-svg-icons"

library.add(
	faCheck,
	faCheckCircle,
	faUser,
	faEnvelope,
	faPhone,
	faAngleRight,
	faEuroSign
)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          bodyAttributes={{
            class: "has-navbar-fixed-top"
          }}
        />
        <div>
          <main>
	    <Navbar>
	    </Navbar>
	    {children}
	  </main>
	  <Contact />
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
